<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">MagTehnica Shooting Range App</a>
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link to="/" class="nav-link">Add Shooter</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/add-option" class="nav-link">Add Shooting Option</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/view-options" class="nav-link">View Shooting Options</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/option-offer" class="nav-link">Teambuilding Offer</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* Global styles */
</style>
