<template>
  <div class="container mt-3">
    <h2>Shooting Options</h2>
    <div v-if="options && options.length">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Number of Bullets</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="option in options" :key="option._id">
            <td>{{ option.name }}</td>
            <td>{{ option.nrbullets }}</td>
            <td>{{ option.type }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <p>No shooting options available.</p>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import ShootingOptionService from '@/services/ShootingOptionService';

export default {
  setup() {
    const options = ref([]);

    const fetchOptions = async () => {
      try {
        const response = await ShootingOptionService.getOptions();
        options.value = response.data;
      } catch (error) {
        console.error("Failed to fetch options:", error);
      }
    };

    onMounted(fetchOptions);

    return {
      options,
    };
  },
};
</script>