<template>
    <div class="container mt-3">
      <h2>Add Shooting Option</h2>
      <form @submit.prevent="submitForm">
        <div class="mb-3">
          <label for="optionName" class="form-label">Name</label>
          <input type="text" class="form-control" id="optionName" v-model="option.name" required>
        </div>
        <div class="mb-3">
          <label for="numberOfBullets" class="form-label">Number of Bullets</label>
          <input type="number" class="form-control" id="numberOfBullets" v-model="option.nrbullets" required>
        </div>
        <div class="mb-3">
          <label for="optionType" class="form-label">Type</label>
          <input type="text" class="form-control" id="optionType" v-model="option.type" required>
        </div>
        <button type="submit" class="btn btn-primary">Add Option</button>
      </form>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import ShootingOptionService from '@/services/ShootingOptionService';
  
  export default {
    setup() {
      const option = ref({
        name: '',
        nrbullets: 0,
        type: ''
      });
  
      const submitForm = async () => {
        try {
          await ShootingOptionService.addOption(option.value);
          alert("Shooting option added successfully.");
          option.value = { name: '', nrbullets: 0, type: '' }; // Reset form
        } catch (error) {
          console.error("Failed to add option:", error);
          alert("Failed to add shooting option.");
        }
      };
  
      return {
        option,
        submitForm,
      };
    },
  };
  </script>
  