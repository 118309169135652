import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// Import your main CSS file (if any)
import './assets/main.css';

const app = createApp(App);

app.use(router);

app.mount('#app');
