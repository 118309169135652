import { createRouter, createWebHistory } from 'vue-router';
import AddShooter from '../views/AddShooter.vue';
import AddShootingOption from '../views/AddShootingOption.vue';
import ViewShootingOptions from '../views/ViewShootingOptions.vue';
import OptionOffer from '../views/OptionOffer.vue';
import PrintOffer from '../views/PrintOffer.vue';

const routes = [
  {
    path: '/',
    name: 'AddShooter',
    component: AddShooter,
  },
  {
    path: '/add-option',
    name: 'AddShootingOption',
    component: AddShootingOption,
  },
  {
    path: '/view-options',
    name: 'ViewShootingOptions',
    component: ViewShootingOptions,
  },
  {
    path: '/option-offer',
    name: 'OptionOffer',
    component: OptionOffer,
  },
  {
    path: '/print-offer',
    name: 'PrintOffer',
    component: PrintOffer,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
