<template>
    <div class="container mt-3">
      <h1>Add Shooters</h1>
      <form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-md-4 mb-4" v-for="(shooter, index) in shooters" :key="`shooter-${index}`">
            <div class="border p-3">
              <h5>Shooter #{{ index + 1 }}</h5>
              <div class="mb-3">
                <label for="shooterName" class="form-label">Shooter's Name</label>
                <input type="text" id="shooterName" class="form-control" v-model="shooter.name" placeholder="Name" required>
              </div>
              <div v-for="(option, oIndex) in shooter.options" :key="`option-${oIndex}`">
                <div class="mb-3">
                  <label class="form-label">Shooting Option</label>
                  <select class="form-select mb-2" v-model="option.selectedOption">
                    <option disabled value="">Please select one</option>
                    <option v-for="opt in options" :key="opt._id" :value="opt._id">{{ opt.name }}</option>
                  </select>
                  <label class="form-label">Extra Bullets</label>
                  <input type="number" class="form-control" v-model="option.extraBullets" placeholder="Extra Bullets">
                </div>
              </div>
              <button type="button" class="btn btn-info btn-sm" @click="addOption(index)">Add Option</button>
              <button type="button" class="btn btn-danger btn-sm" @click="removeShooter(index)">Remove Shooter</button>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-success" @click="addShooter">Add Another Shooter</button>
        <button type="submit" class="btn btn-primary">Submit and Review</button>
      </form>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import ShootingOptionService from '@/services/ShootingOptionService';
  
  export default {
    setup() {
      const shooters = ref([{ name: '', options: [{ selectedOption: '', extraBullets: 0 }] }]);
      const options = ref([]);
  
      const fetchOptions = async () => {
        const response = await ShootingOptionService.getOptions(); // Adjust this according to your API
        options.value = response.data;
      };
  
      const addShooter = () => {
        shooters.value.push({ name: '', options: [{ selectedOption: '', extraBullets: 0 }] });
      };
  
      const removeShooter = (index) => {
        shooters.value.splice(index, 1);
      };
  
      const addOption = (index) => {
        shooters.value[index].options.push({ selectedOption: '', extraBullets: 0 });
      };
  
      const submitForm = () => {
        let content = '<html><body>';
        shooters.value.forEach((shooter, index) => {
          content += `<h3>Shooter #${index + 1}: ${shooter.name}</h3>`;
          shooter.options.forEach((option) => {
            const selectedOption = options.value.find(o => o._id === option.selectedOption);
            content += `<p>Option: ${selectedOption ? selectedOption.name : 'Unknown'} -- ${selectedOption ? selectedOption.type : 'Unknown'} -- Extra Bullets: ${option.extraBullets}</p>`;
          });
        });
        content += '<button onclick="window.print()">Print</button></body></html>';
  
        const reviewWindow = window.open('', '_blank');
        reviewWindow.document.write(content);
        reviewWindow.document.close();
      };
  
      fetchOptions();
  
      return { shooters, options, addShooter, removeShooter, addOption, submitForm };
    },
  };
  </script>
  