<template>
    <div>
        <h1>Offer Details</h1>
        <p>Number of Shooters: {{ numberOfShooters }}</p>
        <div v-for="(option, index) in selectedOptions" :key="index">
            <h2>Option {{ index + 1 }}</h2>
            <p>Name: {{ option.name }}</p>
            <p>Number of Bullets: {{ option.nrbullets }}</p>
            <p>Price: ${{ option.price }}</p>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
    setup() {
        const numberOfShooters = ref(0);
        const selectedOptions = ref([]);

        onMounted(() => {
            const params = new URLSearchParams(window.location.search);
            numberOfShooters.value = params.get('numberOfShooters');
            let index = 1;
            let option = params.get(`option${index}`);
            while (option) {
                selectedOptions.value.push(JSON.parse(option));
                index++;
                option = params.get(`option${index}`);
            }
        });

        return { numberOfShooters, selectedOptions };
    },
};
</script>