<template>
    <div class="d-flex justify-content-center align-items-top" style="height: 100vh;">
        <div class="text-center">
        <div class="alert alert-warning" role="alert">
            PAGE CURRENTLY IN ALPHA
        </div>
        <form @submit.prevent="calculateTotal">
            <div class="mb-3">
                <label for="numberOfShooters" class="form-label">Number of Shooters</label>
                <input type="number" id="numberOfShooters" class="form-control" v-model.number="numberOfShooters" required>
            </div>
            <div class="mb-3">
                <label for="selectedOptions" class="form-label">Select Options</label>
                <select id="selectedOptions" class="form-select" v-model="selectedOptions" multiple>
                    <option v-for="option in options" :key="option.name" :value="option">
                        {{ option.name }} - {{ option.nrbullets }} bullets - ${{ option.price }}
                    </option>
                </select>
            </div>
            <button type="submit" class="btn btn-primary">Calculate Total Price</button>
            <button type="button" class="btn btn-secondary" @click="printOffer">Print Offer</button>
        </form>
        <div v-if="totalPrice > 0" class="mt-3">
            <h2>Total Price: ${{ totalPrice }}</h2>
        </div>
    </div>
</div>
</template>

<script>
import { ref } from 'vue';
import ShootingOptionService from '@/services/ShootingOptionService'; // Ensure this path is correct

export default {
    setup() {
        const numberOfShooters = ref(0);
        const selectedOptions = ref([]);
        const totalPrice = ref(0);
        const options = ref([]);

        const fetchOptions = async () => {
            try {
                // Make an API call to fetch options
                const response = await ShootingOptionService.getOptions();
                options.value = response.data.map(option => ({
                    ...option,
                    price: option.price || Math.floor(Math.random() * (200 - 100 + 1)) + 100 // Assigning random prices if not present
                }));
            } catch (error) {
                console.error("Failed to fetch options:", error);
            }
        };

        const calculateTotal = () => {
            totalPrice.value = selectedOptions.value.reduce((acc, option) => acc + (option.price * numberOfShooters.value), 0);
        };

        const printOffer = () => {
            const params = new URLSearchParams();
            params.append('numberOfShooters', numberOfShooters.value);
            selectedOptions.value.forEach((option, index) => {
                params.append(`option${index + 1}`, JSON.stringify(option));
            });
            window.open(`/print-offer?${params.toString()}`, '_blank');
        };

        fetchOptions();

        return { numberOfShooters, selectedOptions, options, totalPrice, calculateTotal, printOffer };
    },
};
</script>
<style scoped>
.alert {
    color: #850456;
    background-color: #fff3cd;
    border-color: #ffeeba;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
    font-weight: bold; /* Add this line to make the text bold */
    width: 100%;
    margin: auto;
    text-align: center; /* Change this line to remove the centering */
}
</style>