import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://magshooterapi.frostinit.workers.dev', // Replace with your API's base URL
  withCredentials: false, // This is optional depending on your needs
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default {
getOptions() {
  return apiClient.get('/options')
    .then(response => response)
    .catch(error => {
      console.error(error);
    });
},
addOption(option) {
  return apiClient.post('/options', option)
    .then(response => response)
    .catch(error => {
      console.error(error);
    });
},
};
